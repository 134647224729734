body {
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

#main {
  margin-left: 330px;
}

@media (max-width: 1199px) {
  #header {
    left: -330px;
  }
  #main {
    margin-left: 0;
  }
}

a {
  text-decoration: none;
  color: #0645AD;
}

.spinner {
  width: 64px;
  height: 64px;
}

.spinner:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: spinner 1.2s infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

#load-screen {
  text-align: center;
  background: #000000;
	opacity: 1;
	position: fixed;
  z-index: 99999999999999999;
	top: 0px;
  left: 0px;
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
